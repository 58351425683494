import { useQuery } from '@tanstack/react-query';
import apiClient from '../axios/api-client';
import { getAuthAccessToken } from '../../auth/auth-session';

const useGetLoginAllowed = () =>
  useQuery(['loginAllowed'], async () => {
    const client = await apiClient();
    const token = getAuthAccessToken();

    const result = await client.get(`/login-allowed/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return result.data;
  });

export default useGetLoginAllowed;
