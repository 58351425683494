import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export const getBrands = async () => {
  const client = await apiClient();
  const token = getAuthAccessToken();
  const result = await client.get('/steekmaat/get_brands', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
