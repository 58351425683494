import { useState } from 'react';
import apiClient from '../axios/api-client';
import { getAuthAccessToken } from '../../auth/auth-session';

interface UseGetBranchesActivityReturn {
  error: boolean;
  success: boolean;
  onClickBranchesActivity: () => Promise<void>;
}

export const useGetBranchesActivity = (): UseGetBranchesActivityReturn => {
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const onClickBranchesActivity = async (): Promise<void> => {
    try {
      const client = await apiClient();
      const token = getAuthAccessToken();

      await client.get('/branches_activity?create_file=true', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSuccess(true);
      setError(false);
    } catch (err) {
      setError(true);
      setSuccess(false);
    }
  };

  return {
    error,
    success,
    onClickBranchesActivity,
  };
};
