import { getAuth0Connection } from '../app/config';
import { getAuthRequests, setAuthRequests, setAuthState } from './auth-session';
import { getWebAuth } from './webAuth';

declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

const initiateAuth = async () => {
  const webAuth = await getWebAuth();
  if (webAuth) {
    const connection = await getAuth0Connection();
    const state = crypto?.randomUUID ? crypto.randomUUID() : Date.now().toString();
    setAuthState(state);
    const authRequests = getAuthRequests() || 0;

    if (authRequests < 5) {
      setAuthRequests(authRequests + 1);
      webAuth.authorize({ state, connection });
    } else {
      // eslint-disable-next-line no-console
      console.log('Auth: too many retries');
    }
  }
};

export default initiateAuth;
