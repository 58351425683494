import { forwardRef } from 'react';
import SvgIcon, { SvgIconProps } from '../../app/components/bhComponents/SvgIcon';
import { ForwardedRef } from 'app/helpers/types';
const IconInfoFilled = forwardRef((props: SvgIconProps, ref: ForwardedRef<SVGSVGElement>) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    name="IconInfoFilled"
    ref={ref}
    {...props}
  >
    <path id="Path_1504" data-name="Path 1504" d="M0,0H24V24H0Z" fill="none" />
    <path
      id="Path_1505"
      data-name="Path 1505"
      d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,15H11V11h2Zm0-8H11V7h2Z"
    />
  </SvgIcon>
));
IconInfoFilled.displayName = 'IconInfoFilled';
export default IconInfoFilled;
