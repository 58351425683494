import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export const getPurchases = async () => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const result = await client.get('/inkoopen/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getPurchase = async (id: number) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const result = await client.get(`inkoopen/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
