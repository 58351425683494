import axios from 'axios';
import { getAuthAccessToken } from '../../auth/auth-session';
import { getApiBaseUrl } from 'app/config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getUrls: any = {
  search: `/search/`,
  orders: `/bestelling/`,
  tireset: `/bandenset/`,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const postUrls: any = {};

export const postFormData = async (formId: string, formValues: unknown) => {
  const apiBaseUrl = await getApiBaseUrl();

  return axios.post(`${apiBaseUrl}${postUrls[formId]}`, formValues, {
    headers: { Authorization: `Bearer ${getAuthAccessToken()}` },
  });
};

export const get = async (formId: string, queryParams: string) => {
  const apiBaseUrl = await getApiBaseUrl();
  return axios.get(`${apiBaseUrl}${getUrls[formId]}${queryParams}`, {
    headers: { Authorization: `Bearer ${getAuthAccessToken()}` },
  });
};
