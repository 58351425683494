import React, { ChangeEvent, useCallback, useState } from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Toevoegen2AddPhotoInput, Toevoegen2AddPhotoLabel, Toevoegen2UploadedImg } from '../Toevoegen2.styles';
import FormControl from '../../../bhComponents/FormControl';
import FormSelect from '../../../bhComponents/FormSelect';
import { FormSelectOptionType } from '../../../bhComponents/FormSelectDropdown';
import IconEditItem from '../../../../../assets/svg/IconEditItem';
import FormLabel from '../../../bhComponents/FormLabel';
import FormInputText from '../../../bhComponents/FormInputText';
import Typography from '../../../bhComponents/Typography';
import { FormSelectDropdownOptionEventHandler } from '../../../bhComponents/FormSelect/FormSelect';
import FormErrorText from '../../../bhComponents/FormErrorText';
import TogglePanelGroup from '../../../bhComponents/TogglePanelGroup';
import TogglePanel from '../../../bhComponents/TogglePanel';
import FormInputAdornment from '../../../bhComponents/FormInputAdornment';
import IconInfoFilled from '../../../../../assets/svg/IconInfoFilled';
import Tooltip from '../../../bhComponents/Tooltip';
import styles from './AddTyreBlock.module.scss';

export const ProfileDepths: FormSelectOptionType[] = [
  { label: '1 tot 2', value: 'ONETWO' },
  { label: '2 tot 3', value: 'TWOTHREE' },
  { label: '3 tot 4', value: 'THREEFOUR' },
  { label: '4 tot 5', value: 'FOURFIVE' },
  { label: '5 tot 6', value: 'FIVESIX' },
  { label: '6 tot 7', value: 'SIXSEVEN' },
  { label: '7 tot 8', value: 'SEVENEIGHT' },
  { label: '8 +', value: 'EIGHTPLUS' },
];

interface AddTyreBlockProps {
  title: string;
  setUsedFiles: (value: unknown) => void;
  setFileImage: (value: File | undefined) => void;
  usedFiles: string;
  id: number;
  setSchade: (value: (prevProfile: Record<string, number>) => { [p: string]: number }) => void;
  initialSchade?: number;
  initialDotCode?: string;
  initialSelect?: FormSelectOptionType;
  setDotCode: (value: (prevProfile: Record<string, string>) => { [p: string]: string }) => void;
  setProfileDepth: (value: (prevProfile: Record<string, string>) => { [p: string]: string }) => void;
}

const AddTyreBlock = (props: AddTyreBlockProps) => {
  const {
    setUsedFiles,
    setFileImage,
    initialDotCode = '',
    initialSchade = -1,
    initialSelect,
    id,
    setProfileDepth,
    setSchade,
    setDotCode,
    usedFiles,
    title,
  } = props;
  const [invalid, setInvalid] = useState(false);
  const fileId = `file${id}`;
  const profileId = `dropdown${id}Value`;
  const dotId = `dot${id}`;
  const weilId = `weil${id}`;
  const [refElement, setRefElement] = useState<SVGSVGElement | null>(null);
  const [show, setShow] = useState(false);

  const [valueInput, setValueInput] = useState<string>(initialDotCode);
  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 4 || isNaN(Number(event.target.value))) {
      return null;
    }
    setValueInput(event.target.value);
    setDotCode((prevProfile: Record<string, string>) => ({
      ...prevProfile,
      [dotId]: event.target.value,
    }));
  }, []);

  const [valueToggle, setValueToggle] = useState<string>(initialSchade.toString());

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValueToggle(event.target.value);
    setSchade((prevProfile: Record<string, number>) => ({
      ...prevProfile,
      [weilId]: parseInt(event.target.value),
    }));
  };

  const [value, setValue] = useState<FormSelectOptionType | undefined>(initialSelect);
  const handleChange: FormSelectDropdownOptionEventHandler = useCallback((_, options) => {
    setValue(options);
    setProfileDepth((prevProfile: Record<string, string>) => ({
      ...prevProfile,
      [profileId]: options?.value,
    }));
  }, []);

  const handleBlur = useCallback(() => {
    if (valueInput && valueInput.length !== 4) {
      setInvalid(true);
    }
  }, [valueInput]);
  const handleFocus = useCallback(() => {
    setInvalid(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setShow(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <div className={styles.main}>
      <Typography as="h2" variant="heading-24" fontFamily="bold" className={styles.title}>
        {title}
      </Typography>
      <div className={styles['select-wrapper']}>
        <FormControl id="select">
          <FormSelect
            value={value}
            onChange={handleChange}
            label={<FormLabel>Profieldiepte</FormLabel>}
            options={ProfileDepths}
          />
        </FormControl>
        <FormControl error={invalid} id="select">
          <FormInputText
            value={valueInput}
            onChange={handleInputChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            label={<FormLabel>Dot-code</FormLabel>}
            endIconAdornment={
              <FormInputAdornment position="end">
                <IconInfoFilled ref={setRefElement} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
              </FormInputAdornment>
            }
          />
          {invalid && <FormErrorText>Vul een geldige DOT-code in. (4 cijfers)</FormErrorText>}
        </FormControl>
        {show && (
          <Tooltip referenceElement={refElement}>
            De aanduiding DOT en de erop zichtbare cijfers en letters geven aan waar en wanneer een band geproduceerd
            is. De laatste 4 cijfers van de code zijn van belang om in te voeren, deze staan voor de week en het
            jaartal, 1823 staat voor de 18e week van 2023
          </Tooltip>
        )}
        <div className={styles['toggle-wrapper']}>
          <Typography as="h2" fontFamily="bold" variant="heading-16">
            Schade
          </Typography>
          <TogglePanelGroup className={styles.toggle} value={valueToggle} onChange={handleToggleChange}>
            <TogglePanel label="Ja" value="1" />
            <TogglePanel label="Nee" value="0" />
          </TogglePanelGroup>
        </div>
      </div>
      <div className={styles.wrapper}>
        <Typography as="p" className={styles['img-title']} fontFamily="bold">
          Foto`s
        </Typography>
        {usedFiles !== null ? (
          <div className={styles['img-wrapper-upload']}>
            <Toevoegen2UploadedImg src={usedFiles} alt="" />
            <Toevoegen2AddPhotoInput
              type="file"
              name={fileId}
              id={fileId}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setUsedFiles((prev: File) => ({
                  ...prev,
                  [fileId]: URL.createObjectURL(event?.target?.files?.[0] as Blob),
                }));
                setFileImage(event?.target?.files?.[0]);
              }}
            />
            <div className={styles['img-button']}>
              <IconEditItem className={styles.icon} />
              <Toevoegen2AddPhotoLabel htmlFor={fileId}>Afbeelding wijzigen</Toevoegen2AddPhotoLabel>
            </div>
          </div>
        ) : (
          <div className={styles['img-wrapper']}>
            <AddPhotoAlternateIcon className={styles.icon} />
            <Toevoegen2AddPhotoLabel htmlFor={fileId} className={styles['upload-text']}>
              Toevoegen
            </Toevoegen2AddPhotoLabel>
            <Toevoegen2AddPhotoInput
              type="file"
              name={fileId}
              id={fileId}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setUsedFiles((prev: File) => ({
                  ...prev,
                  [fileId]: URL.createObjectURL(event?.target?.files?.[0] as Blob),
                }));
                setFileImage(event?.target?.files?.[0]);
              }}
            />
          </div>
        )}
        <Typography as="p" variant="text-12" className={styles.description}>
          Let op! Zorg dat de velg van voren gefotografeerd is en in het midden gepositioneerd is.
        </Typography>
      </div>
    </div>
  );
};

export default AddTyreBlock;
