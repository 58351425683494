import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

type VerifyCarInfoPropsType = {
  brand: string;
  model: string;
  build_year: number;
};

export const verifyCarInfo = async (data: VerifyCarInfoPropsType) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const response = await client.post(
    'search/car_info/',
    {
      merk: data.brand,
      type: data.model,
      bouwjaar: data.build_year,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
