import axios from 'axios';
import { getApiBaseUrl } from 'app/config';

export default async () => {
  const apiBaseUrl = await getApiBaseUrl();

  return axios.create({
    baseURL: `${apiBaseUrl}/`,
    headers: {
      'Content-type': 'application/json',
    },
  });
};
