import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export type EditProductsPropsType = {
  tyreBandenmerk: string;
  price: number | string;
  storage: string;
  id: {
    first: number;
    second: number;
    third: number;
    fourth: number;
  };
  owner: string;
  runflat: boolean;
  TPMS: boolean;
  season: string;
  profile_depth: {
    dropdown1Value: string;
    dropdown2Value: string;
    dropdown3Value: string;
    dropdown4Value: string;
  };
  dot_code: {
    dot1: string;
    dot2: string;
    dot3: string;
    dot4: string;
  };
  condition: string;
  width: number;
  height: number;
  inches: number;
  velgenMerkOptional: string;
  m_plus_s: boolean;
  drie_PMSF: boolean;
  image_ids: {
    first: number;
    second: number;
    third: number;
    fourth: number;
  };
  damage: {
    schade1: boolean;
    schade2: boolean;
    schade3: boolean;
    schade4: boolean;
  };
  paramId: number;
};

export const updateProduct = async (data: EditProductsPropsType) => {
  const client = await apiClient();
  const token = getAuthAccessToken();
  const response = await client.post(
    `bandenset/update/${data.paramId}`,
    {
      brand: data.tyreBandenmerk,
      price_value: data.price,
      storage: data.storage,
      tires: [
        {
          model: {
            id: data.id.first,
            owner: data.owner,
            is_runflat: data.runflat,
            is_TPMS: data.TPMS,
            season: data.season,
            profile_depth: data.profile_depth.dropdown1Value,
            dot_code: data.dot_code.dot1,
            condition: data.condition,
            width: data.width,
            height: data.height,
            inches: data.inches,
            brand: data.velgenMerkOptional,
            m_plus_s: data.m_plus_s,
            drie_PMSF: data.drie_PMSF,
          },
          image_id: data.image_ids.first,
          damage: data.damage.schade1,
        },
        {
          model: {
            id: data.id.second,
            owner: data.owner,
            is_runflat: data.runflat,
            is_TPMS: data.TPMS,
            season: data.season,
            profile_depth: data.profile_depth.dropdown2Value,
            dot_code: data.dot_code.dot2,
            condition: data.condition,
            width: data.width,
            height: data.height,
            inches: data.inches,
            brand: data.velgenMerkOptional,
            m_plus_s: data.m_plus_s,
            drie_PMSF: data.drie_PMSF,
          },
          image_id: data.image_ids.second,
          damage: data.damage.schade2,
        },
        {
          model: {
            id: data.id.third,
            owner: data.owner,
            is_runflat: data.runflat,
            is_TPMS: data.TPMS,
            season: data.season,
            profile_depth: data.profile_depth.dropdown3Value,
            dot_code: data.dot_code.dot3,
            condition: data.condition,
            width: data.width,
            height: data.height,
            inches: data.inches,
            brand: data.velgenMerkOptional,
            m_plus_s: data.m_plus_s,
            drie_PMSF: data.drie_PMSF,
          },
          image_id: data.image_ids.third,
          damage: data.damage.schade3,
        },
        {
          model: {
            id: data.id.fourth,
            owner: data.owner,
            is_runflat: data.runflat,
            is_TPMS: data.TPMS,
            season: data.season,
            profile_depth: data.profile_depth.dropdown4Value,
            dot_code: data.dot_code.dot4,
            condition: data.condition,
            width: data.width,
            height: data.height,
            inches: data.inches,
            brand: data.velgenMerkOptional,
            m_plus_s: data.m_plus_s,
            drie_PMSF: data.drie_PMSF,
          },
          image_id: data.image_ids.fourth,
          damage: data.damage.schade4,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
