import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export type CreateProductsPropsType = {
  car_brand: string;
  car_model: string;
  build_year: number;
  license_plate: string;
  storage: string;
  tyreBandenmerk: string;
  velgenMerkOptional: string;
  sale_status: string;
  price: number | string;
  runflat: boolean;
  TPMS: boolean;
  season: string;
  profile_depth: {
    dropdown1Value: string;
    dropdown2Value: string;
    dropdown3Value: string;
    dropdown4Value: string;
  };
  dot_code: {
    dot1: string;
    dot2: string;
    dot3: string;
    dot4: string;
  };
  damage: {
    schade1: boolean;
    schade2: boolean;
    schade3: boolean;
    schade4: boolean;
  };
  condition: string;
  width: number;
  height: number;
  inches: number;
  m_plus_s: boolean;
  drie_PMSF: boolean;
  image_ids: {
    first: number;
    second: number;
    third: number;
    fourth: number;
  };
};

export const createProduct = async (data: CreateProductsPropsType) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const response = await client.post(
    `bandenset/compleet`,
    {
      car_brand: data.car_brand,
      car_model: data.car_model,
      build_year: data.build_year,
      license_plate: data.license_plate,
      storage: data.storage,
      brand: data.tyreBandenmerk,
      sale_status: data.sale_status,
      price_value: data.price,
      tires: [
        {
          model: {
            is_runflat: data.runflat,
            is_TPMS: data.TPMS,
            season: data.season,
            profile_depth: data.profile_depth.dropdown1Value,
            dot_code: data.dot_code.dot1,
            condition: data.condition,
            width: data.width,
            height: data.height,
            inches: data.inches,
            brand: data.velgenMerkOptional,
            m_plus_s: data.m_plus_s,
            drie_PMSF: data.drie_PMSF,
          },
          image_id: data.image_ids.first,
          damage: data.damage.schade1,
        },
        {
          model: {
            is_runflat: data.runflat,
            is_TPMS: data.TPMS,
            season: data.season,
            profile_depth: data.profile_depth.dropdown2Value,
            dot_code: data.dot_code.dot2,
            condition: data.condition,
            width: data.width,
            height: data.height,
            inches: data.inches,
            brand: data.velgenMerkOptional,
            m_plus_s: data.m_plus_s,
            drie_PMSF: data.drie_PMSF,
          },
          image_id: data.image_ids.second,
          damage: data.damage.schade2,
        },
        {
          model: {
            is_runflat: data.runflat,
            is_TPMS: data.TPMS,
            season: data.season,
            profile_depth: data.profile_depth.dropdown3Value,
            dot_code: data.dot_code.dot3,
            condition: data.condition,
            width: data.width,
            height: data.height,
            inches: data.inches,
            brand: data.velgenMerkOptional,
            m_plus_s: data.m_plus_s,
            drie_PMSF: data.drie_PMSF,
          },
          image_id: data.image_ids.third,
          damage: data.damage.schade3,
        },
        {
          model: {
            is_runflat: data.runflat,
            is_TPMS: data.TPMS,
            season: data.season,
            profile_depth: data.profile_depth.dropdown4Value,
            dot_code: data.dot_code.dot4,
            condition: data.condition,
            width: data.width,
            height: data.height,
            inches: data.inches,
            brand: data.velgenMerkOptional,
            m_plus_s: data.m_plus_s,
            drie_PMSF: data.drie_PMSF,
          },
          image_id: data.image_ids.fourth,
          damage: data.damage.schade4,
        },
      ],
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
