import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export interface addFavorietPropsType {
  id: number | string;
}

export interface deleteFavorietPropsType {
  id: number | string;
}

export const getFavorieten = async () => {
  const client = await apiClient();
  const token = getAuthAccessToken();
  const result = await client.get('/favorieten/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const addFavoriet = async (data: addFavorietPropsType) => {
  const client = await apiClient();
  const token = getAuthAccessToken();
  const response = await client.post(
    `favorieten/add?tyre_set_id=${data.id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};

export const deleteFavoriet = async (data: deleteFavorietPropsType) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const response = await client.post(
    `favorieten/remove?tyre_set_id=${data.id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
