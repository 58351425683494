import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export const getLabels = async (orderId: number) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const result = await client.get(`transportation_label?order_id=${orderId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
