import { getAuthAccessToken } from '../../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export const getInchmaat = async () => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const result = await client.get('values/inches', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
