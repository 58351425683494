import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export const getVehicleInfo = async (value: string) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const result = await client.get(`vehicle_info?plate=${value}&access_token=${token}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};
