import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

type StatusInventoryToSalePropsType = {
  tyre_set_id: string;
  price: number | string;
};

export const inventoryToSaleProduct = async (data: StatusInventoryToSalePropsType) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const response = await client.post(
    `advertentie/${data.tyre_set_id}?price=${data.price}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
