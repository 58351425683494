import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export const getOrders = async () => {
  const client = await apiClient();
  const token = getAuthAccessToken();
  const result = await client.get('/bestelling/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getOrder = async (id: number) => {
  const client = await apiClient();
  const token = getAuthAccessToken();
  const result = await client.get(`bestelling/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
