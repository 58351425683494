import { useState, useEffect, useMemo } from 'react';
import 'materialize-css/dist/js/materialize.min.js';
import './scss/app.scss';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import useGetLoginAllowed from './app/api/useGetLoginAllowed';
import { getAuthAccessToken } from './auth/auth-session';
import { logout } from './auth/auth-logout';
import { handleAuth } from 'auth/AuthProvider';
import AppLayout from 'app/components/layouts/AppLayout';
import Account from 'app/components/MijnAccountComponents/account';
import AuthCallback from 'app/components/pages/auth-callback';
import Page from 'app/components/page';
import Homepage from 'app/components/pages/home/Homepage';
import Voorraad from 'app/components/pages/voorraad/Voorraad';
import ProductDetails from 'app/components/pages/ProductDetails';
import FAQ from 'app/components/pages/FAQ';
import Toevoegen from 'app/components/pages/Toevoegen';
import Bestellingen from 'app/components/pages/account/Bestellingen/Bestellingen';
import MijnAankopen from 'app/components/pages/account/Mijn-aankopen/MijnAankopen';
import Details from 'app/components/pages/account/Details/Details';
import Favorieten from 'app/components/pages/account/Favorieten/Favorieten';
import Bezorgen from 'app/components/pages/Bezorgen';
import ProductDetailVersionOne from 'app/components/ProductDetailsVersionOne';
import ProductDetailVersionTwo from 'app/components/ProductDetailsVersionTwo';
import Bewerken from 'app/components/Bewerken';
import AankopDetails from 'app/components/pages/account/Details/AankopDetails';
import TyreLabels from 'app/components/pages/tyre-models/TyreLabels';
import ErrorPage from 'app/components/pages/404-page/ErrorPage';
import Splash from 'app/components/pages/splash/Splash';
import { TokenType } from 'auth/common';

const App = () => {
  const [hasValidToken, setValidToken] = useState(false);

  const { email } = useMemo(() => {
    const token = getAuthAccessToken();
    if (token) {
      const { brh_email: email }: TokenType = jwtDecode(token);
      return { email };
    }
    return { email: '' };
  }, []);

  const { data: hasVestiging, isLoading } = useGetLoginAllowed();

  useEffect(() => {
    if (hasVestiging === false && hasValidToken) {
      logout();

      window.location.replace(
        `https://login.mijnbroekhuis.nl/geen-vestiging?app-name=TyreSwap&email=${email}&app-url=${
          window.location.origin
        }&app-intern=${true}`
      );
    }
  }, [hasValidToken, email, hasVestiging]);

  useEffect(() => {
    setValidToken(handleAuth());
  }, []);

  return (
    <Router>
      {isLoading || !hasValidToken ? (
        <>
          <Splash />
          <UnauthorizedRoutes />
        </>
      ) : (
        <MyRoutes />
      )}
    </Router>
  );
};

const UnauthorizedRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route
        path="*"
        element={
          <Page title="Home">
            <AuthCallback />
          </Page>
        }
      />
    </Routes>
  );
};

const MyRoutes = () => {
  const location = useLocation();
  const content = (
    <Routes location={location}>
      <Route
        path="/"
        element={
          <Page title="Home">
            <Homepage />
          </Page>
        }
      />
      <Route
        path="mijn-voorraad"
        element={
          <Page title="Voorraad">
            <Voorraad />
          </Page>
        }
      />
      <Route
        path="/auth/callback"
        element={
          <Page title="Home">
            <AuthCallback />
          </Page>
        }
      />
      <Route path="/user">
        <Route
          path="account"
          element={
            <Page title="Account">
              <Account />
            </Page>
          }
        />
        <Route path="bestellingen">
          <Route
            path=""
            element={
              <Page title="Bestellingen">
                <Bestellingen />
              </Page>
            }
          />
          <Route
            path="order-details/:id"
            element={
              <Page title="Details">
                <Details />
              </Page>
            }
          />
        </Route>
        <Route path="mijn-aankopen">
          <Route
            path=""
            element={
              <Page title="Mijn aankopen">
                <MijnAankopen />
              </Page>
            }
          />

          <Route
            path="purchase-details/:id"
            element={
              <Page title="Details">
                <AankopDetails />
              </Page>
            }
          />
        </Route>
        <Route
          path="favorieten"
          element={
            <Page title="Favorieten">
              <Favorieten />
            </Page>
          }
        />
      </Route>
      <Route
        path="bezorgen/:id"
        element={
          <Page title="Bestellen">
            <Bezorgen />
          </Page>
        }
      />
      <Route
        path="/add"
        element={
          <Page title="Toevoegen">
            <Toevoegen />
          </Page>
        }
      />
      <Route
        path="/bewerken/:id"
        element={
          <Page title="Bewerken">
            <Bewerken />
          </Page>
        }
      />
      <Route
        path="/product/:id"
        element={
          <Page title="Product">
            <ProductDetails />
          </Page>
        }
      />
      <Route
        path="/faq"
        element={
          <Page title="Faq">
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <FAQ />
          </Page>
        }
      />
      <Route
        path="/product-inventory/:id"
        element={
          <Page title="   ">
            <ProductDetailVersionOne />
          </Page>
        }
      />
      <Route
        path="/product-sold/:id"
        element={
          <Page title="  ">
            <ProductDetailVersionTwo />
          </Page>
        }
      />
      <Route
        path="/tyre-labels/:id"
        element={
          <Page title="Tyre Labels">
            <TyreLabels />
          </Page>
        }
      />
      <Route
        path="/*"
        element={
          <Page title="404 Page">
            <ErrorPage />
          </Page>
        }
      />
    </Routes>
  );

  return <AppLayout>{content}</AppLayout>;
};

export default App;
