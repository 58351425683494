import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export const getModels = async (brand: string) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const response = await client.get(`steekmaat/get_models/${brand}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
