import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

export const getPriceRange = async () => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const result = await client.get('/price/price_range', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
