const configCreator = () => {
  let apiBaseUrl: string | undefined;
  let auth0Connection: string | undefined;

  const getConfig = async () => {
    const data = {
      apiBaseUrl: window?.bsConfig?.apiBaseUrl || process.env.REACT_APP_API_BASE_URL,
      auth0Connection: process.env.REACT_APP_AUTH0_CONNECTION,
    };

    if (data?.apiBaseUrl) {
      apiBaseUrl = data.apiBaseUrl;
    }
    if (data?.auth0Connection) {
      auth0Connection = data.auth0Connection;
    }
  };

  const getApiBaseUrl = async (): Promise<string | undefined> => {
    if (!apiBaseUrl) {
      await getConfig();
    }

    return apiBaseUrl;
  };

  const getAuth0Connection = async (): Promise<string | undefined> => {
    if (!auth0Connection) {
      await getConfig();
    }

    return auth0Connection;
  };

  return { getApiBaseUrl, getAuth0Connection };
};

export const { getApiBaseUrl, getAuth0Connection } = configCreator();

export const getWebsiteUrl = (): string =>
  (typeof window !== 'undefined' && window.bsConfig?.websiteUrl) || 'http://localhost:3000';

export const getApiToken = (): string => (typeof window !== 'undefined' && window.bsConfig?.apiToken) || '';
