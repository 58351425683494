import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

type DeleteProductPropsType = {
  id: number;
};

export const deleteProduct = async (data: DeleteProductPropsType) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const response = await client.delete(`bandenset/${data.id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
};
