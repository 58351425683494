import { getAuthAccessToken } from '../../auth/auth-session';
import apiClient from 'app/axios/api-client';

type PlaceOrderPropsType = {
  tyre_set_id: string;
};

export const placeOrder = async (data: PlaceOrderPropsType) => {
  const client = await apiClient();
  const token = getAuthAccessToken();

  const response = await client.post(
    `bestelling/${data.tyre_set_id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response.data;
};
